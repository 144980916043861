exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-agreement-index-jsx": () => import("./../../../src/pages/agreement/index.jsx" /* webpackChunkName: "component---src-pages-agreement-index-jsx" */),
  "component---src-pages-cafe-index-jsx": () => import("./../../../src/pages/cafe/index.jsx" /* webpackChunkName: "component---src-pages-cafe-index-jsx" */),
  "component---src-pages-cleaning-denim-index-jsx": () => import("./../../../src/pages/cleaning-denim/index.jsx" /* webpackChunkName: "component---src-pages-cleaning-denim-index-jsx" */),
  "component---src-pages-cleaning-index-jsx": () => import("./../../../src/pages/cleaning/index.jsx" /* webpackChunkName: "component---src-pages-cleaning-index-jsx" */),
  "component---src-pages-cleaning-sneaker-index-jsx": () => import("./../../../src/pages/cleaning-sneaker/index.jsx" /* webpackChunkName: "component---src-pages-cleaning-sneaker-index-jsx" */),
  "component---src-pages-contact-index-jsx": () => import("./../../../src/pages/contact/index.jsx" /* webpackChunkName: "component---src-pages-contact-index-jsx" */),
  "component---src-pages-draft-news-index-jsx": () => import("./../../../src/pages/draft/news/index.jsx" /* webpackChunkName: "component---src-pages-draft-news-index-jsx" */),
  "component---src-pages-draft-posts-index-jsx": () => import("./../../../src/pages/draft/posts/index.jsx" /* webpackChunkName: "component---src-pages-draft-posts-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-laundry-out-index-jsx": () => import("./../../../src/pages/laundry-out/index.jsx" /* webpackChunkName: "component---src-pages-laundry-out-index-jsx" */),
  "component---src-pages-media-index-jsx": () => import("./../../../src/pages/media/index.jsx" /* webpackChunkName: "component---src-pages-media-index-jsx" */),
  "component---src-pages-news-index-jsx": () => import("./../../../src/pages/news/index.jsx" /* webpackChunkName: "component---src-pages-news-index-jsx" */),
  "component---src-pages-news-strapi-information-slug-jsx": () => import("./../../../src/pages/news/{StrapiInformation.slug}.jsx" /* webpackChunkName: "component---src-pages-news-strapi-information-slug-jsx" */),
  "component---src-pages-policy-index-jsx": () => import("./../../../src/pages/policy/index.jsx" /* webpackChunkName: "component---src-pages-policy-index-jsx" */),
  "component---src-pages-posts-strapi-post-slug-jsx": () => import("./../../../src/pages/posts/{StrapiPost.slug}.jsx" /* webpackChunkName: "component---src-pages-posts-strapi-post-slug-jsx" */),
  "component---src-pages-qa-index-jsx": () => import("./../../../src/pages/qa/index.jsx" /* webpackChunkName: "component---src-pages-qa-index-jsx" */),
  "component---src-pages-repair-index-jsx": () => import("./../../../src/pages/repair/index.jsx" /* webpackChunkName: "component---src-pages-repair-index-jsx" */),
  "component---src-pages-self-laundry-balukocard-index-jsx": () => import("./../../../src/pages/self-laundry/balukocard/index.jsx" /* webpackChunkName: "component---src-pages-self-laundry-balukocard-index-jsx" */),
  "component---src-pages-self-laundry-balukocard-protect-index-jsx": () => import("./../../../src/pages/self-laundry/balukocard/protect/index.jsx" /* webpackChunkName: "component---src-pages-self-laundry-balukocard-protect-index-jsx" */),
  "component---src-pages-self-laundry-detergent-index-jsx": () => import("./../../../src/pages/self-laundry/detergent/index.jsx" /* webpackChunkName: "component---src-pages-self-laundry-detergent-index-jsx" */),
  "component---src-pages-self-laundry-index-jsx": () => import("./../../../src/pages/self-laundry/index.jsx" /* webpackChunkName: "component---src-pages-self-laundry-index-jsx" */),
  "component---src-pages-self-laundry-pet-laundry-index-jsx": () => import("./../../../src/pages/self-laundry/pet-laundry/index.jsx" /* webpackChunkName: "component---src-pages-self-laundry-pet-laundry-index-jsx" */),
  "component---src-pages-self-laundry-smartlaundry-index-jsx": () => import("./../../../src/pages/self-laundry/smartlaundry/index.jsx" /* webpackChunkName: "component---src-pages-self-laundry-smartlaundry-index-jsx" */),
  "component---src-pages-smartlaundry-app-link-index-jsx": () => import("./../../../src/pages/smartlaundry-app-link/index.jsx" /* webpackChunkName: "component---src-pages-smartlaundry-app-link-index-jsx" */),
  "component---src-pages-store-list-index-jsx": () => import("./../../../src/pages/store-list/index.jsx" /* webpackChunkName: "component---src-pages-store-list-index-jsx" */),
  "component---src-pages-strapi-store-page-slug-jsx": () => import("./../../../src/pages/{StrapiStore.page_slug}.jsx" /* webpackChunkName: "component---src-pages-strapi-store-page-slug-jsx" */),
  "component---src-pages-terms-of-cardservice-index-jsx": () => import("./../../../src/pages/terms-of-cardservice/index.jsx" /* webpackChunkName: "component---src-pages-terms-of-cardservice-index-jsx" */),
  "component---src-pages-uservoice-strapi-uservoice-uid-jsx": () => import("./../../../src/pages/uservoice/{StrapiUservoice.uid}.jsx" /* webpackChunkName: "component---src-pages-uservoice-strapi-uservoice-uid-jsx" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-videos-strapi-video-uid-jsx": () => import("./../../../src/pages/videos/{StrapiVideo.uid}.jsx" /* webpackChunkName: "component---src-pages-videos-strapi-video-uid-jsx" */)
}

